import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

const Pie = () => {
  return (
    <MDBFooter color="unique-color" className="font-small pt-4 mt-4 pie">
      <div className="footer-copyright text-center py-3">
        <p>
          {process.env.REACT_APP_NAME} | Sistema de Simpatizantes | &copy;&nbsp;
          <a href="https://sii.mx" target="_blank" className="text-reset">
            sii.mx
          </a>&nbsp;2023
        </p>
      </div>
    </MDBFooter>
  );
};

export default Pie;
